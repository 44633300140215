import UserDropdown from '@components/Shared/Dropdown/UserDropdown'
import UserContributor from '../AccessComponents/UserContributor'
import { useFormikContext } from 'formik'
import TeamMultiselect from '@components/Shared/Multiselect/TeamMultiselect'
import TeamContributorCard from '../AccessComponents/TeamContributorCard'
import { CONTRIBUTOR_RIGHTS, CONTRIBUTOR_TYPE } from '@utils/constants'
import useTranslations from '@locales/useTranslations'
import { User } from '@lib/types/user'
import useContributorActions from '@lib/hooks/useContributorActions'
import Panel from '@components/Ui/Panel'
import ContributorContainer from '../AccessComponents/ContributorContainer'

export interface ISharedTab {
  isOwnerEditable?: boolean
  resourceOwner: User
  onOwnerChange: Function
}

const SharedTab = ({
  isOwnerEditable = true,
  resourceOwner,
  onOwnerChange,
}: ISharedTab) => {
  const { values }: any = useFormikContext()

  const { t } = useTranslations()

  const { OWNER } = CONTRIBUTOR_RIGHTS(t)
  const { onContributorAdd: onUserAdd, ...userActions } = useContributorActions(
    CONTRIBUTOR_TYPE.USER,
    resourceOwner,
  )
  const projectResponsible = values?.userContributors?.find(
    (account) => account.id === resourceOwner?.id,
  )

  const restUserContributors = values?.userContributors?.filter(
    (account) => account.id !== resourceOwner?.id,
  )
  const {
    onContributorAdd: onTeamAdd,
    onTeamDelete,
    ...teamActions
  } = useContributorActions(CONTRIBUTOR_TYPE.TEAM, resourceOwner)

  return (
    <>
      {resourceOwner && (
        <UserContributor
          key={resourceOwner?.id}
          account={resourceOwner}
          access={OWNER.value}
          onOwnerChange={onOwnerChange}
          disabled={!isOwnerEditable}
        />
      )}

      {projectResponsible && (
        <UserContributor
          key={projectResponsible?.id}
          account={projectResponsible.account}
          disabled
          access={projectResponsible.access}
          showMoreActions={false}
          isProjectResponsible
        />
      )}

      {!!values?.teamContributors?.length && (
        <ContributorContainer
          title={t('access.teams')}
          contributors={values.teamContributors.map((teamContributor) => (
            <TeamContributorCard
              key={teamContributor.team.id}
              teamContributor={teamContributor}
              onTeamDelete={onTeamDelete}
              projectResponsibleId={projectResponsible?.account?.id}
              resourceOwnerId={resourceOwner?.id}
              {...teamActions}
            />
          ))}
        />
      )}

      {restUserContributors?.length > 0 && (
        <ContributorContainer
          title={t('access.individualUsers')}
          contributors={
            <Panel
              title={t('access.users')}
              buttonAs="div"
              shadowClassName=""
              labelClassName="text-sm"
              className="mb-3"
              btnClassName="bg-electric-violet-50"
            >
              <div className="pl-3">
                <div>
                  {restUserContributors.map(({ account, access, ...rest }) => (
                    <UserContributor
                      key={account.id}
                      account={account}
                      access={access}
                      {...rest}
                      {...userActions}
                    />
                  ))}
                </div>
              </div>
            </Panel>
          }
        />
      )}

      <div className="flex justify-between w-100">
        <div className="flex-grow pr-2">
          <TeamMultiselect
            placeholder={`+ ${t('buttons.addTeam')}`}
            onChange={onTeamAdd}
            dataParser={(data) =>
              data.filter(
                (t) =>
                  !values.teamContributors.find(({ team }) => team.id === t.id),
              )
            }
          />
        </div>

        <div className="flex-grow pl-2">
          <UserDropdown
            placeholder={`+ ${t('buttons.addUser')}`}
            onChange={onUserAdd}
            dataParser={(data) =>
              data.filter(
                (user) =>
                  user.id !== resourceOwner.id &&
                  !values.userContributors.find(
                    ({ account }) => user.id === account.id,
                  ),
              )
            }
          />
        </div>
      </div>
    </>
  )
}

export default SharedTab
