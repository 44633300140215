import UserContributor from './UserContributor'
import ContributorMoreActions from './ContributorMoreActions'
import useTranslations from '@locales/useTranslations'
import { TeamContributor } from '@lib/types/project'
import Panel from '@components/Ui/Panel'
import classNames from 'classnames'
import { UserAccess } from '@lib/graphql/__generated__/graphql'
import { CONTRIBUTOR_RIGHTS } from '@utils/constants'
import { UUID } from '@lib/types/general'

interface ITeamContributorPros {
  key: string
  disabled?: boolean
  teamContributor: TeamContributor
  projectResponsibleId?: UUID
  resourceOwnerId?: UUID
  onTeamDelete?: Function
  onTeamMembersAccessChange?: Function
  onPermissionChange?: Function
  onContributorDelete?: Function
  onMakeResponsible?: Function
}

const TeamContributorCard = ({
  teamContributor,
  onTeamDelete,
  onTeamMembersAccessChange,
  disabled = false,
  projectResponsibleId,
  resourceOwnerId,
  ...rest
}: ITeamContributorPros) => {
  const { t } = useTranslations()
  const { OWNER } = CONTRIBUTOR_RIGHTS(t)

  const { team, contributors } = teamContributor
  const filterContributors = (contributors, ids) =>
    contributors.filter(({ account }) => ids.includes(account.id))
  const { teamMembers, guests } = team.members.reduce(
    (acc, { account, isGuest }) => {
      if (isGuest) {
        acc.guests.push(account.id)
      } else {
        acc.teamMembers.push(account.id)
      }
      return acc
    },
    { teamMembers: [], guests: [] },
  )
  const membersContributors = filterContributors(contributors, teamMembers)
  const guestsContributors = filterContributors(contributors, guests)

  const { id, name } = team

  const actionItems = [
    {
      id,
      label: (
        <span className="text-oxford-gray-800">
          {t('buttons.setMembersAsViewers')}
        </span>
      ),
      action: () => onTeamMembersAccessChange?.(id, UserAccess.Viewer),
    },
    {
      id,
      label: (
        <span className="text-oxford-gray-800">
          {t('buttons.setMembersAsEditors')}
        </span>
      ),
      action: () => onTeamMembersAccessChange?.(id, UserAccess.Editor),
    },
    {
      id,
      label: <span className="text-watermelon-500">{t('buttons.delete')}</span>,
      action: () => onTeamDelete?.(id),
    },
  ]

  return (
    <Panel
      title={name}
      buttonAs="div"
      shadowClassName=""
      labelClassName="text-sm"
      renderRight={(open) => (
        <div className="flex align-middle">
          {!disabled && (
            <ContributorMoreActions
              actionItems={actionItems}
              disabled={disabled}
            />
          )}
          <i
            className={classNames([
              'far fa-chevron-down text-oxford-gray-400 transform transition-transform text-lg',
              {
                'rotate-180': open,
              },
            ])}
          />
        </div>
      )}
      className="mb-3"
      btnClassName="bg-electric-violet-50"
    >
      <div className="pl-3 relative">
        <div className="members-contributor-container">
          {membersContributors.map(({ account, access }) => (
            <div className="contributors" key={account.id}>
              <UserContributor
                disabled={disabled}
                key={account.id}
                account={account}
                access={account?.id === resourceOwnerId ? OWNER.value : access}
                teamId={id}
                showMoreActions={false}
                isProjectResponsible={account?.id === projectResponsibleId}
                {...rest}
              />
            </div>
          ))}

          {guestsContributors.length > 0 && (
            <div>
              <span className="text-oxford-gray-500">
                {t('access.teamGuests')}
              </span>
              {guestsContributors.map(({ account, access }) => (
                <>
                  <div className="contributors">
                    <UserContributor
                      disabled={disabled}
                      key={account.id}
                      account={account}
                      access={access}
                      teamId={id}
                      showMoreActions={false}
                      {...rest}
                    />
                  </div>
                </>
              ))}
            </div>
          )}
        </div>
      </div>
    </Panel>
  )
}

export default TeamContributorCard
